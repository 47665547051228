package tta.destinigo.talktoastro.feature_custom_order

import co.touchlab.kermit.Logger
import com.arkivanov.decompose.ComponentContext
import com.arkivanov.essenty.lifecycle.doOnDestroy
import com.arkivanov.essenty.lifecycle.doOnStart
import kotlinx.coroutines.CoroutineExceptionHandler
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.SupervisorJob
import kotlinx.coroutines.cancel
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.launch
import org.koin.core.component.KoinComponent
import org.koin.core.component.inject
import tta.destinigo.talktoastro.feature_custom_order.data.CustomOrderListData
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderListRequest
import tta.destinigo.talktoastro.feature_custom_order.data.request.SetEnrollRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.DataX
import tta.destinigo.talktoastro.feature_custom_order.domain.CustomOrderListRepo
import tta.destinigo.talktoastro.shared.remote.Resources

class CustomOrderComponent(
    context: ComponentContext,
    private val onBackPress:()->Unit,
    private val onCradClick:(CustomOrderListData) -> Unit
): ComponentContext by context, KoinComponent {

    private val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
        Logger.e { "Coroutine Exception: ${throwable.message}" }
        throwable.printStackTrace()
    }

    private val coroutineScope = CoroutineScope(SupervisorJob() + Dispatchers.Main + exceptionHandler)
    private val api: CustomOrderListRepo by inject()

    private val _state = MutableStateFlow<List<DataX>>(emptyList())
    val state: StateFlow<List<DataX>> = _state
   // private val _state = MutableStateFlow<CustomOrderListState>(CustomOrderListState.Loading(false))
    private var currentPage = 1
//    val state get() = _state.asStateFlow()
    private var isFetchingNextPage = false
    private var isLastPage = false

    fun onBackClick() = onBackPress.invoke()
    fun onCardClick(data:CustomOrderListData) = onCradClick.invoke(data)
    init {
        lifecycle.doOnStart {
            Logger.d { "Custom Order Screen Component onStart" }
            coroutineScope.launch {
                getCustomOrderList()
            }
        }

        lifecycle.doOnDestroy {
            Logger.d { "Custom Order Screen Component onDestroy" }
            coroutineScope.cancel()
        }
    }

    private suspend fun getCustomOrderList() {
        if (isFetchingNextPage || isLastPage) return
        isFetchingNextPage = true

        api.getCustomOrderInfo(CustomOrderListRequest(page = currentPage.toString())).collect {
            when (it) {
                is Resources.Error -> onError(it.msg ?: "Unexpected Error")
                is Resources.Loading -> onLoading(it.isLoading)
                is Resources.Success -> {
                    if (it.data?.data?.isEmpty() == true) {
                        isLastPage = true
                        Logger.d { "No more chat history pages to load." }
                    }else {
                        val currentData = _state.value
                        val updatedData = currentData + (it.data?.data ?: emptyList())
                        _state.emit(updatedData)
                        currentPage++
                    }
                }
            }
        }
        isFetchingNextPage = false
    }

    private suspend fun enrollCustomOrder(request: SetEnrollRequest){
        api.setEnrollCustomOrder(request).collect{
            when(it){
                is Resources.Error -> {
                    Logger.d { "enrollCustomOrder error ${it.msg}" }
                }
                is Resources.Loading -> {
                    Logger.d { "enrollCustomOrder loading ${it.isLoading}" }
                }
                is Resources.Success -> {
                    Logger.d { "enrollCustomOrder success ${it.data}" }
                }
            }
        }
    }

    private suspend fun onError(message: String) = coroutineScope.launch {
       // _state.emit(CustomOrderListState.Error(message))
        isFetchingNextPage = false
    }

    private suspend fun onLoading(isLoading: Boolean) = coroutineScope.launch {
       // _state.emit(CustomOrderListState.Loading(isLoading))
    }

    fun loadNextPage() = coroutineScope.launch {
        getCustomOrderList()
    }
}

package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_custom_order.data.CustomOrderLiveSession

@Serializable
data class CustomOrderDetails(
    @SerialName("category") val category: String, // Scientific Astrology
    @SerialName("content_type") val contentType: String, // Live
    @SerialName("created_at") val createdAt: String, // 2024-04-23 16:45:47
    @SerialName("deleted_at") val deletedAt: String?, // null
    @SerialName("description") val description: String, // loreum ipsum dummy text live
    @SerialName("display_price") val displayPrice: String?, // 200
    @SerialName("duration") val duration: Int, // 30
    @SerialName("expert_id") val expertId: Int, // 52631
    @SerialName("heading") val heading: String, // test custom order live
    @SerialName("id") val id: Int, // 1
    @SerialName("image") val image: String, // 1713870947.jpg
    @SerialName("image_url") val imageUrl: String, // http://localhost/winywayapi/public/customorder/1713870947.jpg
    @SerialName("language") val language: String, // English
    @SerialName("service") val service: String, // Paid
    @SerialName("status") val status: String, // 1
    @SerialName("updated_at") val updatedAt: String, // 2024-04-23 19:41:06,
    @SerialName("live_session") val liveSession: List<CustomOrderLiveSession>?,
)

package tta.destinigo.talktoastro.feature_custom_order.persentation

import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.width
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.DateRange
import androidx.compose.material.icons.filled.Shop
import androidx.compose.material.icons.filled.Timer
import androidx.compose.material.icons.filled.Today
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.painterResource

@Composable
fun SessionDetailsScreen(
    contentType: String,
    startDate: String,
    endDate: String,
    sessionTime: String,
    activeDays: String,
    onEnquiryClick: () -> Unit = {}
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .padding(horizontal = 16.dp, vertical = 8.dp)
    ) {
        if(contentType.isNotEmpty()){
            SessionDetailItem(
                label = "Section Type :",
                value = contentType,
                iconRes = Icons.Default.Shop
            )
        }

        Spacer(modifier = Modifier.height(4.dp))
        if(startDate.isNotEmpty()) {
            SessionDetailItem(
                label = "Start Date :",
                value = startDate,
                iconRes = Icons.Default.DateRange
            )
        }
        Spacer(modifier = Modifier.height(4.dp))
        if(endDate.isNotEmpty()) {
            SessionDetailItem(
                label = "End Date :",
                value = endDate,
                iconRes = Icons.Default.DateRange
            )
        }
        Spacer(modifier = Modifier.height(4.dp))
        if(sessionTime.isNotEmpty()) {
            SessionDetailItem(
                label = "Session Time :",
                value = sessionTime,
                iconRes = Icons.Default.Timer
            )
        }
        Spacer(modifier = Modifier.height(4.dp))
        if(activeDays.isNotEmpty()){
        SessionDetailItem(
            label = "Active Days :",
            value = activeDays,
            iconRes = Icons.Default.Today
        )}
        Spacer(modifier = Modifier.height(16.dp))
    }
}

@Composable
fun SessionDetailItem(
    label: String,
    value: String,
    iconRes: ImageVector
) {
    Row(
        verticalAlignment = Alignment.CenterVertically,
        modifier = Modifier.fillMaxWidth()
    ) {
        Icon(
            imageVector = iconRes,
            contentDescription = null,
            modifier = Modifier.size(20.dp),
            tint = MaterialTheme.colorScheme.onBackground
        )
        Spacer(modifier = Modifier.width(6.dp))
        Text(
            text = label,
            style = MaterialTheme.typography.bodyMedium.copy(fontWeight = FontWeight.SemiBold)
        )
        Spacer(modifier = Modifier.width(4.dp))
        Text(
            text = value,
            style = MaterialTheme.typography.bodyMedium.copy(fontWeight = FontWeight.SemiBold)
        )
    }
}

package tta.destinigo.talktoastro.feature_custom_order.persentation

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.ExperimentalLayoutApi
import androidx.compose.foundation.layout.FlowRow
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.layout.wrapContentHeight
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.rememberLazyListState
import androidx.compose.material3.ExperimentalMaterial3Api
import androidx.compose.material3.Text
import androidx.compose.material3.windowsizeclass.ExperimentalMaterial3WindowSizeClassApi
import androidx.compose.material3.windowsizeclass.WindowWidthSizeClass
import androidx.compose.material3.windowsizeclass.calculateWindowSizeClass
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import tta.destinigo.talktoastro.feature_custom_order.CustomOrderComponent
import tta.destinigo.talktoastro.shared.local.openPlayStore
import tta.destinigo.talktoastro.shared.persentation.CommonTopAppBar
import tta.destinigo.talktoastro.shared.persentation.SampleDialog
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_COMPACT
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_ELSE
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_EXPEND
import tta.destinigo.talktoastro.shared.theme.Scale.WINDOW_WIDTH_SIZE_MEDIUM
import tta.destinigo.talktoastro.shared.theme.background_color
import tta.destinigo.talktoastro.shared.theme.user_app_play_store_url

@OptIn(ExperimentalMaterial3Api::class, ExperimentalMaterial3WindowSizeClassApi::class, ExperimentalFoundationApi::class,
    ExperimentalLayoutApi::class
)
@Composable
fun CustomHostPage(component: CustomOrderComponent) {
    val state by component.state.collectAsState()
    val openDialog = remember { mutableStateOf(false) }
    val windowSizeClass = calculateWindowSizeClass().widthSizeClass
    val widthModifier = when (windowSizeClass) {
        WindowWidthSizeClass.Compact -> WINDOW_WIDTH_SIZE_COMPACT
        WindowWidthSizeClass.Medium -> WINDOW_WIDTH_SIZE_MEDIUM
        WindowWidthSizeClass.Expanded -> WINDOW_WIDTH_SIZE_EXPEND
        else -> WINDOW_WIDTH_SIZE_ELSE
    }

    val chatHistoryList by component.state.collectAsState()
    val lazyListState = rememberLazyListState()

    if (openDialog.value) {
        SampleDialog(
            onDismissRequest = { openDialog.value = false },
            onPlayStore = {
                openPlayStore(user_app_play_store_url)
            },
        )
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .wrapContentHeight()
            .background(background_color),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier = widthModifier
                .background(background_color)
                .fillMaxWidth()
                .wrapContentHeight()
                .padding(start = 10.dp, end = 10.dp)
        ) {
            CommonTopAppBar(
                title = "Transform Your life",
                modifier = Modifier,
                onBackClick = { component.onBackClick() }
            )
            Spacer(modifier = Modifier.height(16.dp))

            if (chatHistoryList.isNotEmpty() && chatHistoryList.firstOrNull()?.items?.isNotEmpty() == true) {
                LazyColumn(
                    modifier = Modifier.fillMaxSize(),
                    state = lazyListState,
                    verticalArrangement = Arrangement.spacedBy(16.dp),
                ) {
                    chatHistoryList.forEach { chatHistory ->
                        // Add category name as a header
                        item {
                            chatHistory.categoryName?.let { categoryName ->
                                Text(
                                    text = categoryName,
                                    modifier = Modifier
                                        .fillMaxWidth()
                                        .padding(vertical = 8.dp),
                                )
                            }
                        }

                        // Use FlowRow to display items in a table-like layout
                        item {
                            FlowRow(
                                modifier = Modifier.padding(4.dp),
                                horizontalArrangement = Arrangement.spacedBy(4.dp),
                                maxItemsInEachRow = 4
                            ) {
                                chatHistory.items.forEach { data ->
                                    Box(
                                        modifier = Modifier
                                           // .wrapContentSize()
                                            .size(350.dp)
                                            //.border(1.dp, Color.Blue)
                                    ) {
                                        CustomOrderCard(
                                            false,
                                            data = data,
                                            onCustomOrderCardClick = {data->
                                              //  openDialog.value = true
                                                component.onCardClick(data)
                                            }
                                        )
                                    }
                                }
                            }
                        }
                    }
                }

            } else {
                // Show a placeholder or message when the list is empty
                Text(
                    text = "No Data available.",
                    color = Color.Gray,
                    modifier = Modifier.align(Alignment.CenterHorizontally)
                )
            }
        }
    }
    // Trigger `loadNextPage()` when user scrolls to the end
    LaunchedEffect(lazyListState) {
        snapshotFlow { lazyListState.layoutInfo.visibleItemsInfo }
            .collect { visibleItems ->
                val lastVisibleItemIndex = visibleItems.lastOrNull()?.index ?: 0
                val totalItemsCount = lazyListState.layoutInfo.totalItemsCount

                if (lastVisibleItemIndex == totalItemsCount - 1) {
                    // User has scrolled to the end of the list
                    component.loadNextPage()
                }
            }
    }
}



@Composable
fun calculateDynamicHeight(
    itemCount: Int,
    itemsPerRow: Int,
    itemHeight: Dp,
    verticalSpacing: Dp
): Dp {
    val rowCount = (itemCount + itemsPerRow - 1) / itemsPerRow // Ceiling division for total rows
    return (itemHeight * rowCount) + (verticalSpacing * (rowCount - 1))
}



package tta.destinigo.talktoastro.feature_custom_order.data

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Expert(
    @SerialName("Expertreviews") val expertReviews: String?,
    @SerialName("account_approval") val accountApproval: Int, // 1
    @SerialName("account_holder_name") val accountHolderName: String, // Pragyan Paramita Das
    @SerialName("account_number") val accountNumber: String, // 52160017395496
    @SerialName("account_type") val accountType: String, // Saving
    @SerialName("bank_name") val bankName: String, // Bandhan Bank
    @SerialName("calls_without_commission") val callsWithoutCommission: Int, // 0
    @SerialName("consultant_profession") val consultantProfession: String, // Self Employed
    @SerialName("created_at") val createdAt: String, // 2024-01-16 20:33:17
    @SerialName("currency") val currency:String?=null, // null
    @SerialName("device_details") val deviceDetails: String, // IPHONE_13
    @SerialName("display_education_section") val displayEducationSection: Int, // 1
    @SerialName("display_profession_section") val displayProfessionSection: Int, // 1
    @SerialName("duration") val duration: Int, // 5
    @SerialName("email") val email: String, // pragyan1009@gmail.com
    @SerialName("expert_flash_price") val expertFlashPrice: String?=null, // null
    @SerialName("expert_price") val expertPrice: Int, // 6
    @SerialName("first_name") val firstName: String, // Astro
    @SerialName("flash_discount") val flashDiscount: Int, // 0
    @SerialName("free_chat_expert_id") val freeChatExpertId: String?=null, // null
    @SerialName("free_expert_id") val freeExpertId: String?=null, // null
    @SerialName("free_optional") val freeOptional: Int, // 1
    @SerialName("full_name") val fullName: String, // Astro Pragyan
    @SerialName("gender") val gender: String, // Female
    @SerialName("id") val id: Int, // 52631
    @SerialName("ifsc_code") val ifscCode: String, // BDBL0001615
    @SerialName("international_flag") val internationalFlag: Int, // 0
    @SerialName("is_call") val isCall: Int, // 1
    @SerialName("is_celebrity") val isCelebrity: String, // 0
    @SerialName("is_chat") val isChat: Int, // 1
    @SerialName("is_custom_order") val isCustomOrder: Int, // 0
    @SerialName("is_free_call") val isFreeCall: Int, // 1
    @SerialName("is_freeze") val isFreeze: String, // 1
    @SerialName("is_new") val isNew: String, // 0
    @SerialName("is_premium") val isPremium: String, // 0
    @SerialName("is_trending") val isTrending: String, // 0
    @SerialName("is_video") val isVideo: Int, // 0
    @SerialName("language") val language: String, // English
    @SerialName("last_msg_sent_at") val lastMsgSentAt: String?=null, // null
    @SerialName("last_name") val lastName: String, // Pragyan
    @SerialName("last_online_time") val lastOnlineTime: String, // 2024-01-19 17:16:17
    @SerialName("level") val level: Int, // 9
    @SerialName("linkedin_url") val linkedinUrl: String,
    @SerialName("login_device") val loginDevice: String, // android
    @SerialName("mobile") val mobile: String, // 9867027389
    @SerialName("mobile_code") val mobileCode: String, // 91
    @SerialName("notification_token") val notificationToken: String, // Devvice_TOKEN_TO_SEND_NOTIFICATION
    @SerialName("offer_letter") val offerLetter: String,
    @SerialName("optinout") val optinout: Int, // 1
    @SerialName("pan_image") val panImage: String,
    @SerialName("pan_number") val panNumber: String, // AUKPD2261Q
    @SerialName("professional_approval") val professionalApproval: Int, // 1
    @SerialName("profile_description") val profileDescription: String, // Astro Pragyan is a celebrated Astrologer from Odisha with an experience of more than 10 years in the field of Astrology. She is an expert of Vedic Astrology and has been continuously practicing it from the last 10 years. Talking about his educational qualifications, he attained the divine knowledge of Astrology from Shree Maharishi College of Vedic Astrology.Over the years, she has served a number of people across many parts of the world, providing proper consultation regarding Relationship, Finance, Health, Career, Education, or any other sort of problem people are facing in their day to day life.One thing that makes her quite unique is her straight forward and transparent behaviour i.e She will only tell you the truth as she doesn't include any personal opinion in her reports and never tries to manipulate astrological facts for giving false commitments and predictions. Moreover, she uses a blend of modern guidance and remedies combining traditional and modern scientific techniques in astrology which helps her to provide better outcomes and results.Besides this, you can also contact her regarding various personal issues such as Marriage Consultation, Child Name Consultation, Gemstone Consultation, Love & Relationship, Health Consultation, Child Birth, Legal matters and much more.
    @SerialName("profile_image") val profileImage: String, // profile0mIAPG08-37-38.jpg
    @SerialName("profile_image_url") val profileImageUrl: String, // https://prod.winyway.com/images/UserImages/profile0mIAPG08-37-38.jpg
    @SerialName("redirect_to") val redirectTo: String, // dashboard
    @SerialName("refer_code") val referCode: String?=null, // null
    @SerialName("service_categories_description") val serviceCategoriesDescription: String,
    @SerialName("service_category_id") val serviceCategoryId: Int, // 17
    @SerialName("service_type") val serviceType: String, // Fitness Experts
    @SerialName("skills") val skills: String, // Vedic Astrologer
    @SerialName("status") val status: Int, // 0
    @SerialName("terms_conditions") val termsConditions: String, // 0
    @SerialName("total_chat") val totalChat: String?=null, // null
    @SerialName("total_payment_international") val totalPaymentInternational: String?=null, // null
    @SerialName("totalcall") val totalcall: String?=null, // null
    @SerialName("totalpayment") val totalpayment: Int, // 7
    @SerialName("type") val type: String, // consultant
    @SerialName("updated_at") val updatedAt: String, // 2024-03-04 18:01:18
    @SerialName("video") val video: String,
    @SerialName("video_url") val videoUrl: String,
    @SerialName("whatsapp_updates") val whatsappUpdates: Int, // 1
    @SerialName("winyway_commission") val winywayCommission: Int, // 0
    @SerialName("winyway_flash_price") val winywayFlashPrice:String?=null, // null
    @SerialName("winyway_price") val winywayPrice: Int, // 6
    @SerialName("year_of_experience") val yearOfExperience: Int // 10
)

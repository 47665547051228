package tta.destinigo.talktoastro.feature_custom_order.data.api

import io.ktor.client.call.body
import io.ktor.client.plugins.ClientRequestException
import io.ktor.client.plugins.ServerResponseException
import io.ktor.client.request.post
import io.ktor.client.request.setBody
import io.ktor.client.request.url
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderDeatilsRequest
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderListRequest
import tta.destinigo.talktoastro.feature_custom_order.data.request.SetEnrollRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.CreateCustomOrderResponse
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderDetailData
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderEnrollResponse
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderLIstResponseCategoryWise
import tta.destinigo.talktoastro.feature_custom_order.data.response.Data
import tta.destinigo.talktoastro.feature_custom_order.data.response.EnrollResponseData
import tta.destinigo.talktoastro.shared.remote.KtorApi
import tta.destinigo.talktoastro.shared.remote.Resources

class CustomOrderApi: KtorApi() {

    companion object{
        // Custom Order
        private const val CUSTOM_ORDER_PREFIX = "custom-order/"


        //    const val CREATE_CUSTOM_ORDER_PRODUCT = CUSTOM_ORDER_PREFIX + "create"
        const val GET_CUSTOM_ORDER_DETAIL = CUSTOM_ORDER_PREFIX + "get-details"
        const val GET_CUSTOM_ORDER_LIST = CUSTOM_ORDER_PREFIX + "get-list"
        const val SET_ENROLL_CUSTOM_ORDER = CUSTOM_ORDER_PREFIX + "enroll"
        const val GET_PURCHASE_HISTORY_LIST = CUSTOM_ORDER_PREFIX + "purchase-history"
        const val GET_ZOOM_MEETING_DETAILS  = CUSTOM_ORDER_PREFIX + "get-zoom-meeting-details"
        //    const val GET_PURCHASE_AMOUNT = CUSTOM_ORDER_PREFIX + "get-amount"
        const val GET_CUSTOM_ORDER_PURCHASE_INFO = CUSTOM_ORDER_PREFIX + "recharge"
        const val GET_CUSTOM_ORDER_RAZORPAY_ORDER_ID = CUSTOM_ORDER_PREFIX + "get-order-id"
        const val GET_CUSTOM_ORDER_PURCHASE_STATUS = CUSTOM_ORDER_PREFIX + "recharge-status-verify"
        const val GET_CUSTOM_ORDER_PURCHASE_SUCCESS = CUSTOM_ORDER_PREFIX + "success"
        const val SAVE_BOT_MSG = CUSTOM_ORDER_PREFIX + "save-enquiry-messages"
        const val GET_BOT_MSG = CUSTOM_ORDER_PREFIX + "get-enquiry-messages"
    }


    private suspend fun getCustomOrderListBody(request: CustomOrderListRequest) = client.post {
        url(GET_CUSTOM_ORDER_LIST)
        setBody(request)
    }.body<CustomOrderLIstResponseCategoryWise>()

    private suspend fun setEnrolCustomOrder(request: SetEnrollRequest) = client.post {
        url(SET_ENROLL_CUSTOM_ORDER)
        setBody(request)
    }.body<CustomOrderEnrollResponse>()


    private suspend fun getCustomOrderDetails(request: CustomOrderDeatilsRequest) = client.post {
        url(GET_CUSTOM_ORDER_DETAIL)
        setBody(request)
    }.body<CreateCustomOrderResponse>()


    suspend fun customOrderListBody(request: CustomOrderListRequest): Resources<Data> {
        return withContext(Dispatchers.Default) {
            try {
                val topConsultantResponse = getCustomOrderListBody(request)

                if (topConsultantResponse.status.not()) {
                    return@withContext Resources.Error(
                        topConsultantResponse.message
                    )
                }

                return@withContext Resources.Success(topConsultantResponse.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun enrollCustomOrder(request: SetEnrollRequest): Resources<EnrollResponseData>{
        return withContext(Dispatchers.Default) {
            try {
                val response = setEnrolCustomOrder(request)

                if (response. status.not()) {
                    return@withContext Resources.Error(
                        response.message
                    )
                }

                return@withContext Resources.Success(response.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }


    suspend fun getCustomOrderDetailsData(request: CustomOrderDeatilsRequest): Resources<CustomOrderDetailData>{
        return withContext(Dispatchers.Default) {
            try {
                val response = getCustomOrderDetails(request)

                if (response. status.not()) {
                    return@withContext Resources.Error(
                        response.message
                    )
                }

                return@withContext Resources.Success(response.data)

            } catch (e: ClientRequestException) {
                Resources.Error("Client request error: ${e.message}")
            } catch (e: ServerResponseException) {
                Resources.Error("Server response error: ${e.message}")
            } catch (e: Exception) {
                Resources.Error("Unexpected error: ${e.message}")
            }
        }
    }
}
package tta.destinigo.talktoastro.feature_custom_order.data.response

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import tta.destinigo.talktoastro.feature_custom_order.data.CustomOrderListData

@Serializable
data class DataX(
    @SerialName("category_name") val categoryName: String, // Healing
    @SerialName("items") val items: List<CustomOrderListData>
)

package tta.destinigo.talktoastro.feature_custom_order.domain

import kotlinx.coroutines.flow.Flow
import kotlinx.coroutines.flow.flow
import tta.destinigo.talktoastro.feature_custom_order.data.api.CustomOrderApi
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderDeatilsRequest
import tta.destinigo.talktoastro.feature_custom_order.data.request.CustomOrderListRequest
import tta.destinigo.talktoastro.feature_custom_order.data.request.SetEnrollRequest
import tta.destinigo.talktoastro.feature_custom_order.data.response.CustomOrderDetailData
import tta.destinigo.talktoastro.feature_custom_order.data.response.Data
import tta.destinigo.talktoastro.feature_custom_order.data.response.EnrollResponseData
import tta.destinigo.talktoastro.shared.remote.Resources

class CustomOrderListRepo( private val api: CustomOrderApi) {

    fun getCustomOrderInfo(request: CustomOrderListRequest): Flow<Resources<Data>>{
        return flow {
            emit(Resources.Loading())
            val response = api.customOrderListBody(request)
            emit(response)
            emit(Resources.Loading(false))
        }
    }


    fun setEnrollCustomOrder(request: SetEnrollRequest): Flow<Resources<EnrollResponseData>>{
        return flow {
            emit(Resources.Loading())
            val resources = api.enrollCustomOrder(request)
            emit(resources)
            emit(Resources.Loading(false))
        }
    }

    fun getCustomOrderDetails(request: CustomOrderDeatilsRequest): Flow<Resources<CustomOrderDetailData>>{
        return flow {
            emit(Resources.Loading())
            val resources = api.getCustomOrderDetailsData(request)
            emit(resources)
            emit(Resources.Loading(false))
        }
    }
}